import { isSampleAvailable } from "@/utils/isSampleAvailable";
import { ProductVariationWithCatalogPagesResponse } from "@/web-client/api";
import { CategoryEntity, ProductDetailResponse } from "@/web-client/api";
import { useMemo } from "react";

interface UseProductDetailResponse {
  detailedCategory: CategoryEntity;
  displayName: string;

  telephoneContactable: boolean;
  formContactable: boolean;
  hasCatalogEntity: boolean;
  hasContactMethods: boolean;

  trackingPagePath: string;
  minPrice?: number;
  maxPrice?: number;

  hasSample: boolean;
  applyableProductVariations: ProductVariationWithCatalogPagesResponse[];
}

const useProductDetail = (
  product: ProductDetailResponse,
): UseProductDetailResponse => {
  const detailedCategory = useMemo(
    () => product.categories[product.categories.length - 1],
    [product],
  );

  const displayName = useMemo(() => {
    return product.full_name;
  }, [product]);

  const telephoneContactable = useMemo<boolean>(
    () => product.maker.can_telephone_contacted,
    [product],
  );

  const formContactable = useMemo<boolean>(
    () => product.maker.can_form_contacted,
    [product],
  );

  const hasCatalogEntity = useMemo(() => {
    return product.variations.some(
      (variation) => variation.catalogs.length > 0,
    );
  }, [product]);

  const hasContactMethods = useMemo(() => {
    return telephoneContactable || formContactable || hasCatalogEntity;
  }, [telephoneContactable, formContactable, hasCatalogEntity]);

  const trackingPagePath = useMemo<string>(() => {
    return `/products/${product.id}?maker_id=${product.maker.id}`;
  }, [product]);

  // 「廃売品じゃない」プロダクトバリエーション
  const undiscontinuedProductVariations = useMemo(
    () => product.variations.filter((variation) => !variation.is_discontinued),
    [product],
  );

  const prices = useMemo(() => {
    const values = [];
    undiscontinuedProductVariations.forEach((variation) => {
      // 0は基本的に表示しない値なので、0の場合は無視する
      if (variation.min_price) values.push(variation.min_price);

      if (variation.max_price) values.push(variation.max_price);
    });

    return values;
  }, [undiscontinuedProductVariations]);

  const minPrice = useMemo<number | undefined>(() => {
    if (prices.length == 0) return undefined;

    return Math.min(...prices);
  }, [prices]);

  const maxPrice = useMemo<number | undefined>(() => {
    if (prices.length == 0) return undefined;

    // MaxPriceが未設定のものがある場合は、最大値を表示しない
    if (
      undiscontinuedProductVariations.filter(
        (variation) => !variation.max_price,
      ).length > 0
    ) {
      return undefined;
    }

    return Math.max(...prices);
  }, [prices, undiscontinuedProductVariations]);

  const applyableProductVariations = useMemo(
    () => product.variations.filter(isSampleAvailable),
    [product],
  );

  const hasSample = useMemo(
    () => applyableProductVariations.length > 0,
    [applyableProductVariations],
  );

  return {
    detailedCategory,
    displayName,

    telephoneContactable,
    formContactable,
    hasCatalogEntity,
    hasContactMethods,

    trackingPagePath,

    minPrice,
    maxPrice,

    hasSample,
    applyableProductVariations,
  };
};
export default useProductDetail;
