import {
  ProductVariationWithCatalogPagesResponse,
  UploadImageResponse,
} from "@/web-client/api";
import { ProductDetailResponse } from "@/web-client/api";
import { Dispatch, SetStateAction, useMemo, useState } from "react";

interface UseProductVariationGroupResponse {
  groups: ProductVariationGroup[];
  currentGroup: ProductVariationGroup | undefined;
  setCurrentGroup: Dispatch<SetStateAction<ProductVariationGroup>>;
}

export type ProductVariationGroup = {
  key: string;
  name: string;
  image: UploadImageResponse;
  variations: ProductVariationWithCatalogPagesResponse[];
};

const useProductVariationGroup = (
  product: ProductDetailResponse,
): UseProductVariationGroupResponse => {
  // プロダクトバリエーションをグループ分けした配列
  // 最終的にレスポンスに入ってくる
  const groupByGruopName = useMemo<{
    [key: string]: {
      has_group_name: boolean;
      variations: ProductVariationWithCatalogPagesResponse[];
    };
  }>(() => {
    return product.variations
      .sort((a, b) => {
        // 廃盤品は後方に表示されるようにする
        return Number(a.is_discontinued) - Number(b.is_discontinued);
      })
      .reduce((acc, item) => {
        // グループ名がない場合は、画像ハッシュをキーにする
        const groupName = item.group_name || item.upload_image.image_hash;

        if (!acc[groupName]) {
          acc[groupName] = {
            has_group_name: !!item.group_name,
            variations: [],
          };
        }

        acc[groupName].variations.push(item);

        return acc;
      }, {});
  }, [product.variations]);

  const groups = useMemo<ProductVariationGroup[]>(() => {
    return Object.keys(groupByGruopName).map((key) => {
      const { has_group_name, variations: productVariations } =
        groupByGruopName[key];

      // 1.グループ名があれば、そのままグループ名を使う
      // 2.グループ名がなく、バリエーションが1つより多い場合は、空文字を使う
      // 3.グループ名がなく、バリエーションが1つの場合は、バリエーション名を使う（バリエーション名がない場合は空文字を使う)
      const name = has_group_name
        ? key
        : productVariations.length > 1
        ? ""
        : productVariations[0].name || "";

      return {
        key,
        name,
        image: productVariations[0].upload_image,
        variations: productVariations,
      };
    });
  }, [groupByGruopName]);

  const [currentGroup, setCurrentGroup] = useState<
    ProductVariationGroup | undefined
  >(undefined);

  return {
    groups,
    currentGroup,
    setCurrentGroup,
  };
};
export default useProductVariationGroup;
