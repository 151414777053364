import { UploadImageResponse } from "@/web-client/api";
import Link from "next/link";
import { FC } from "react";

type SimpleCatalog = {
  id: number;
  cover_image?: UploadImageResponse;
  pages?: number[];
};

type Props = {
  catalog: SimpleCatalog;
};

export const CatalogThumb: FC<Props> = ({ catalog }): JSX.Element => {
  return (
    <Link
      href={
        catalog.pages
          ? `/catalogs/${catalog.id}?page=${catalog.pages[0]}`
          : `/catalogs/${catalog.id}`
      }
      className="block w-full aspect-square bg-no-repeat bg-contain bg-center"
      style={{
        backgroundImage: `url(${catalog.cover_image.urls.small})`,
      }}
    ></Link>
  );
};
