import useDisclosure from "@/hooks/useDisclosure";
import { FC, PropsWithChildren, ReactNode, useCallback } from "react";

interface Props {
  renderModal: (isOpen: boolean, close: VoidFunction) => ReactNode;
}

const ModalBtn: FC<PropsWithChildren<Props>> = ({
  children,
  renderModal,
}): JSX.Element => {
  const { closeHandler, openHandler, isOpen } = useDisclosure();
  const clickHandler = useCallback(() => {
    openHandler();
  }, [openHandler]);

  return (
    <>
      <button onClick={clickHandler} type={"button"}>
        {children}
      </button>
      {isOpen && renderModal(isOpen, closeHandler)}
    </>
  );
};

export default ModalBtn;
