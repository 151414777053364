import { FC } from "react";
import Modal from "@/components/Modal";
import { UploadImageResponse } from "@/web-client/api";
import useImageLoader from "@/hooks/useImageLoader";

interface Props {
  image: UploadImageResponse;
  alt: string;
  isOpen: boolean;
  closeHandler(): void;
}

const ModalOriginalImage: FC<Props> = ({
  isOpen,
  closeHandler,
  image,
  alt,
}) => {
  const { isLoading, isComplete } = useImageLoader(image.urls.original);

  return (
    <Modal
      contentStyle={{ maxWidth: 640 }}
      isOpen={isOpen}
      closeHandler={closeHandler}
    >
      <div className="p-16 ">
        {isLoading && (
          <img
            className="block w-full h-full aspect-square object-none"
            src={"/progress.gif"}
            alt={"読み込み中"}
          />
        )}

        {isComplete && (
          <img
            className="block w-full h-full aspect-square object-contain"
            src={image.urls.original}
            alt={alt}
          />
        )}
      </div>
    </Modal>
  );
};

export default ModalOriginalImage;
