import { FC } from "react";

interface Props {
  label: string;
  onClick: () => void;
  iconSuffix: "Mail" | "Link" | "Catalogue" | "Telephone" | "Shipping";
}

const ProductContactBtn: FC<Props> = ({
  label,
  iconSuffix,
  onClick,
}): JSX.Element => {
  return (
    <button
      className={[
        "flex items-center space-x-4 ",
        "small:border small:border-primary small:rounded-full small:p-8 small:bg-gray-100 small:justify-center small:space-x-8",
      ].join(" ")}
      onClick={onClick}
    >
      <i className={`icon icon-${iconSuffix}`} />
      <span className="laptop:underline text-xs font-bold">{label}</span>
    </button>
  );
};
export default ProductContactBtn;
