import client from "@/utils/api/client";
import useFetch from "@/hooks/useFetch";
import { ProductDetailResponse } from "@/web-client/api";

interface ProductHookResponse {
  product: ProductDetailResponse;
  error?: Error;
  mutate: (
    updatedProduct?: ProductDetailResponse,
    shouldRevalidate?: boolean,
  ) => void;
}

const useProduct = (
  id: number,
  initialProduct?: ProductDetailResponse,
): ProductHookResponse => {
  const {
    data: product,
    error,
    mutate,
  } = useFetch<ProductDetailResponse>(
    `/products/${id}`,
    async () => {
      const { data } = await client.productsIdGet({ id });

      return data;
    },
    initialProduct,
    { waitForAuth: true },
  );

  return { product, error, mutate };
};

export default useProduct;
