import { ProductDetailResponse, ProductKind } from "@/web-client/api";
import { useMemo } from "react";

type UseProductUnitsReturn = {
  width: string;
  height: string;
  depth: string;
};

const CategoryLightId = 388; // [建材]照明・ライト
const CategoryEquipmentId = 329; // [建材]設備機器
const CategoryPartitionId = 407; // [建材]建具・開口部・間仕切り
const Lists = [CategoryLightId, CategoryEquipmentId, CategoryPartitionId];

const useProductUnits = (
  product: ProductDetailResponse,
): UseProductUnitsReturn => {
  // インテリア表記にするかどうか
  const forInterior = useMemo(() => {
    if (product.kind === ProductKind.INTERIOR) {
      return true;
    }

    // 建材の中の例外
    const firstCategory = product.categories[0];

    return Lists.includes(firstCategory.id);
  }, [product]);

  const height = useMemo(() => {
    return forInterior ? "高さ" : "長さ";
  }, [forInterior]);

  const depth = useMemo(() => {
    return forInterior ? "奥行き" : "厚み";
  }, [forInterior]);

  return {
    width: "幅",
    height,
    depth,
  };
};
export default useProductUnits;
