import {
  ProductVariationWithCatalogPagesResponse,
  ProductVariationWithProductResponse,
} from "@/web-client/api";
import { useMemo } from "react";

const useDeliveryTimeString = (
  productVariation:
    | ProductVariationWithCatalogPagesResponse
    | ProductVariationWithProductResponse,
) => {
  const deadlineString = useMemo(() => {
    const { is_long_delivery_time, is_discontinued } = productVariation;

    if (is_discontinued) return "-";

    if (is_long_delivery_time) return "長納期(受注生産・輸入品)";

    return "標準在庫品";
  }, [productVariation]);

  return deadlineString;
};
export default useDeliveryTimeString;
