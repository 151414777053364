import { FC, MouseEventHandler, PropsWithChildren, useMemo } from "react";
import CartBtnWrapper from "@/components/CartBtnWrapper";
import {
  ListItemKind,
  ProductDetailResponse,
  ProductKind,
  ProductVariationWithCatalogPagesResponse,
  SearchConditionValueEntity,
} from "@/web-client/api";
import useDisplayPrice from "@/hooks/useDisplayPrice";
import { useElementSize } from "usehooks-ts";
import useDevice from "@/hooks/useDevice";
import {
  foramtFireProtectionMaterial,
  formatFireRegistance,
  formatFlamePreventionRegulation,
  formatFormaldehyde,
} from "@/utils/formatProductDetail";
import useAnalytics from "@/hooks/useAnalytics";
import { ProductVariationGroup } from "@/features/product_variation/hooks/useProductVariationGroup";
import ZoomableImage from "@/components/ZoomableImage";
import LinkText from "@/components/LinkText";
import { theme } from "tailwind.config";
import ModalBtn from "@/components/ModalBtn";
import ModalCatalogs from "@/features/catalog/components/ModalCatalogs";
import useProductVariationGroupTable from "@/features/product_variation/hooks/useProductVariationGroupTable";
import useProductUnits from "@/features/product/hooks/useProductUnits";
import formatNumber from "@/utils/formatNumber";
import MinimumLikeBtn from "@/features/like/components/MinimumLikeBtn";
import MinimumAddListBtn from "@/features/list/components/MinimumAddListBtn";
import useScrollControl, {
  ScrollControlDirection,
} from "@/hooks/useScrollControl";
import IconArrowRight from "../../../assets/imgs/svg/icon-arrow-right.svg";
import IconArrowLeft from "../../../assets/imgs/svg/icon-arrow-left.svg";
import useDeliveryTimeString from "@/hooks/useDeliveryTimeString";
import useCart from "@/hooks/useCart";
import SampleItemTSSDeliveryLabel from "@/features/product_variation/components/SampleItemTSSDeliveryLabel";
import useProductVariation from "@/features/product_variation/hooks/useProductVariation";
import { LikeMutateHandler } from "@/features/like/hooks/useLike";
import useLikeToProduct from "@/features/like/hooks/useLikeToProduct";

type Props = {
  group: ProductVariationGroup;
  product: ProductDetailResponse;
  mutate?: LikeMutateHandler<ProductVariationWithCatalogPagesResponse>;
};

const ProductTable: FC<Props> = ({ group, product, mutate }) => {
  const { event } = useAnalytics();
  const { isSp } = useDevice();
  const [ref, { width }] = useElementSize();
  const {
    hasItemNumber,
    hasPrice,
    hasSize,
    hasSizeText,
    hasMaterial,
    hasMaterialText,
    hasFireProtectionMaterial,
    hasFlamePreventionRegulation,
    hasFireRegistance,
    hasFormaldehyde,
    hasRemarks,
    hasProductUrl,
    hasCatalog,
  } = useProductVariationGroupTable(group);

  const thWidth = useMemo(() => {
    return Math.min(Math.max(80, width / 5), 160);
  }, [width]);

  const tdWidth = useMemo(() => {
    const BorderWidth = 1;
    const Offset = width - thWidth - BorderWidth * 2;

    if (isSp) {
      if (group.variations.length === 1) {
        return Offset;
      }

      return Math.max(Offset / 3.2, 320);
    } else {
      if (group.variations.length === 1) {
        // 1つの場合は、セルの幅を640に固定して、大きくなりすぎないようにする
        return 640;
      }

      // 2つの場合、画面に全て収まるようにする
      if (group.variations.length === 2) {
        return Offset / group.variations.length;
      }

      // 3つ以上の場合は、残りの幅を2.8で割ったものを幅とする(2.8なのは3つ目以降がすこし見える程度にしたいから)
      return Math.max(Offset / 2.8, 240);
    }
  }, [width, isSp, thWidth, group.variations]);

  // 詳細検索項目データをテーブル表示用に整形したもの
  const searchConditions = useMemo<
    {
      label: string;
      values: SearchConditionValueEntity[][];
    }[]
  >(() => {
    // 表示する詳細検索項目のidを抜き出す
    const searchConditions = group.variations.reduce((acc, variation) => {
      const search_condition_group_ids = variation.search_condition_groups.map(
        ({ id, label }) => {
          return {
            id,
            label,
          };
        },
      );
      acc.push(...search_condition_group_ids);

      return acc;
    }, []);

    // idを元に重複を整理する
    const uniqSearchConditions = Array.from(
      new Map(
        searchConditions.map((searchCondition) => [
          searchCondition.id,
          searchCondition,
        ]),
      ).values(),
    );

    return uniqSearchConditions.map((searchCondition) => {
      group.variations.forEach((variation) => {
        // 指定の詳細検索項目を検索
        const target = variation.search_condition_groups.find(
          (search_condition_group) =>
            search_condition_group.id === searchCondition.id,
        );

        if (!searchCondition.values) searchCondition.values = [];
        searchCondition.values.push(target ? target.values : []);
      });

      return searchCondition;
    });
  }, [group.variations]);

  const {
    ref: scrollContainerRef,
    scrollBy,
    isMax,
    isMin,
  } = useScrollControl(ScrollControlDirection.Horizontal);

  return (
    <div className="relative group/hoge" ref={ref}>
      <section
        ref={scrollContainerRef}
        className="w-full overflow-x-scroll mx-auto"
      >
        <table
          style={{ width: 1 }} // widthを実数値で指定しないと、td,thのwidthを指定できないため1を指定
          className={[
            "table-fixed", // このクラスがないとtd,thのwidthが効かない
            "text-center bg-white text-sm overflow-auto [&_td]:border [&_td]:border-primary mx-auto",
            "[&>*:nth-child(even)]:bg-gray-100 [&>*:nth-child(even)]:bg-opacity-40",
          ].join(" ")}
        >
          <TableRow>
            <StickyTableHeader width={thWidth} />
            {group.variations.map((variation) => (
              <TableDataForAction
                key={variation.id}
                productVariation={variation}
                product={product}
                width={tdWidth}
                mutate={mutate}
              />
            ))}
          </TableRow>

          {hasItemNumber && (
            <TableRow>
              <StickyTableHeader width={thWidth}>品番</StickyTableHeader>
              {group.variations.map((variation) => (
                <TableData em={true} width={tdWidth} key={variation.id}>
                  {variation.item_number ? (
                    variation.is_discontinued ? (
                      <>
                        <span className="text-danger font-bold">廃番</span>(
                        {variation.item_number})
                      </>
                    ) : (
                      <>{variation.item_number}</>
                    )
                  ) : variation.is_discontinued ? (
                    <span className="text-danger font-bold">廃番</span>
                  ) : (
                    <>-</>
                  )}
                </TableData>
              ))}
            </TableRow>
          )}

          {hasPrice && product.maker.is_contracted && (
            <TableRow>
              <StickyTableHeader width={thWidth}>価格(税抜)</StickyTableHeader>
              {group.variations.map((variation) => (
                <TableData em={true} width={tdWidth} key={variation.id}>
                  {!variation.is_discontinued ? (
                    <Price productVariation={variation} />
                  ) : (
                    <>-</>
                  )}
                </TableData>
              ))}
            </TableRow>
          )}

          {hasSize && (
            <TableRow>
              <StickyTableHeader width={thWidth}>サイズ</StickyTableHeader>
              {group.variations.map((variation) => (
                <TableData width={tdWidth} key={variation.id}>
                  <Size productVariation={variation} product={product} />
                </TableData>
              ))}
            </TableRow>
          )}

          {hasSizeText && (
            <TableRow>
              <StickyTableHeader width={thWidth}>
                サイズについて
              </StickyTableHeader>
              {group.variations.map((variation) => (
                <TableData width={tdWidth} key={variation.id}>
                  {variation.size_text}
                </TableData>
              ))}
            </TableRow>
          )}

          {hasMaterial && (
            <TableRow>
              <StickyTableHeader width={thWidth}>素材</StickyTableHeader>
              {group.variations.map((variation) => (
                <TableData width={tdWidth} key={variation.id}>
                  {variation.materials[variation.materials.length - 1].name}
                </TableData>
              ))}
            </TableRow>
          )}

          {hasMaterialText && (
            <TableRow>
              <StickyTableHeader width={thWidth}>
                素材について
              </StickyTableHeader>
              {group.variations.map((variation) => (
                <TableData width={tdWidth} key={variation.id}>
                  {variation.material_text}
                </TableData>
              ))}
            </TableRow>
          )}

          {hasFireProtectionMaterial && (
            <TableRow>
              <StickyTableHeader width={thWidth}>
                防火材料(建築基準法)
              </StickyTableHeader>
              {group.variations.map((variation) => (
                <TableData width={tdWidth} key={variation.id}>
                  {variation.fire_protection_material &&
                    foramtFireProtectionMaterial(
                      variation.fire_protection_material,
                    )}
                </TableData>
              ))}
            </TableRow>
          )}

          {hasFlamePreventionRegulation && (
            <TableRow>
              <StickyTableHeader width={thWidth}>
                防炎規制(消防法)
              </StickyTableHeader>
              {group.variations.map((variation) => (
                <TableData width={tdWidth} key={variation.id}>
                  {variation.flame_prevention_regulation &&
                    formatFlamePreventionRegulation(
                      variation.flame_prevention_regulation,
                    )}
                </TableData>
              ))}
            </TableRow>
          )}

          {hasFireRegistance && (
            <TableRow>
              <StickyTableHeader width={thWidth}>耐火性能</StickyTableHeader>
              {group.variations.map((variation) => (
                <TableData width={tdWidth} key={variation.id}>
                  {variation.fire_resistance &&
                    formatFireRegistance(variation.fire_resistance)}
                </TableData>
              ))}
            </TableRow>
          )}

          {hasFormaldehyde && (
            <TableRow>
              <StickyTableHeader width={thWidth}>
                ホルムアルデヒド等級
              </StickyTableHeader>
              {group.variations.map((variation) => (
                <TableData width={tdWidth} key={variation.id}>
                  {variation.formaldehyde &&
                    formatFormaldehyde(variation.formaldehyde)}
                </TableData>
              ))}
            </TableRow>
          )}

          {product.maker.is_contracted && (
            <TableRow>
              <StickyTableHeader width={thWidth}>納期</StickyTableHeader>
              {group.variations.map((variation) => (
                <TableDataForDeliveryTime
                  key={variation.id}
                  width={tdWidth}
                  productVariation={variation}
                />
              ))}
            </TableRow>
          )}

          {hasRemarks && (
            <TableRow>
              <StickyTableHeader width={thWidth}>備考</StickyTableHeader>
              {group.variations.map((variation) => (
                <TableData width={tdWidth} key={variation.id}>
                  {variation.remarks}
                </TableData>
              ))}
            </TableRow>
          )}

          {/* 詳細検索項目 */}
          {searchConditions &&
            searchConditions.map(({ label, values }) => (
              <TableRow key={label}>
                <StickyTableHeader width={thWidth}>{label}</StickyTableHeader>
                {values.map((searchConditionValues, index) => (
                  <TableData width={tdWidth} key={index}>
                    {searchConditionValues.length > 0 && (
                      <ul>
                        {searchConditionValues.map((value) => (
                          <li key={value.id}>{value.label}</li>
                        ))}
                      </ul>
                    )}
                  </TableData>
                ))}
              </TableRow>
            ))}

          {hasProductUrl && (
            <TableRow>
              <StickyTableHeader width={thWidth}>公式サイト</StickyTableHeader>
              {group.variations.map((variation) => (
                <TableData width={tdWidth} key={variation.id}>
                  {variation.product_url ? (
                    <button
                      type="button"
                      onClick={() => {
                        event("click_button", {
                          content_type: "web",
                          item_id: `PROD_${product.id}`,
                        });
                        window.open(variation.product_url, "_blank");
                      }}
                    >
                      <LinkText
                        label={"外部サイト"}
                        outer={true}
                        wrapperClassName=""
                      />
                    </button>
                  ) : (
                    <></>
                  )}
                </TableData>
              ))}
            </TableRow>
          )}

          {hasCatalog && (
            <TableRow>
              <StickyTableHeader width={thWidth}>カタログ</StickyTableHeader>
              {group.variations.map((variation) => (
                <TableData width={tdWidth} key={variation.id}>
                  {variation.catalogs.length > 0 ? (
                    <ModalBtn
                      renderModal={(isOpen, closeHandler) => (
                        <ModalCatalogs
                          product={product}
                          isOpen={isOpen}
                          closeHandler={closeHandler}
                        />
                      )}
                    >
                      <LinkText
                        label={"カタログ"}
                        outer={false}
                        wrapperClassName=""
                      />
                    </ModalBtn>
                  ) : variation.catalog_url ? (
                    <button
                      type="button"
                      onClick={() => {
                        event("click_button", {
                          content_type: "catalog",
                          item_id: `PROD_${product.id}`,
                        });
                        window.open(variation.catalog_url, "_blank");
                      }}
                    >
                      <LinkText
                        label={"外部カタログ"}
                        outer={true}
                        wrapperClassName=""
                      />
                    </button>
                  ) : (
                    <></>
                  )}
                </TableData>
              ))}
            </TableRow>
          )}
        </table>
        {!isSp && group.variations.length > 2 && (
          <>
            {!isMin && (
              <div className="absolute flex items-center left-[12px] inset-y-0 z-[100] pointer-events-none opacity-0 group-hover/hoge:opacity-100 transition-all">
                <div className="pointer-events-auto">
                  <ScrollButton
                    direction="left"
                    onClick={() => {
                      scrollBy(tdWidth * -0.8, 0.3, "easeInOut");
                    }}
                  />
                </div>
              </div>
            )}

            {!isMax && (
              <div className="absolute flex items-center right-[12px] inset-y-0 z-[100] pointer-events-none opacity-0 group-hover/hoge:opacity-100 transition-all">
                <div className="pointer-events-auto">
                  <ScrollButton
                    direction="right"
                    onClick={() => {
                      scrollBy(tdWidth * 0.8, 0.3, "easeInOut");
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </section>
    </div>
  );
};
export default ProductTable;

type StickyTableHeaderProps = {
  width: number;
};

const StickyTableHeader: FC<PropsWithChildren<StickyTableHeaderProps>> = ({
  children,
  width,
}): JSX.Element => {
  return (
    <th
      className={[
        "sticky top-0 left-0 py-8 px-8 bg-gray-100 text-xs z-10 font-normal border-t border-b border-primary",
        "after:content-[''] after:w-full after:h-full after:absolute after:inset-0 after:border-l after:border-primary",
      ].join(" ")}
      style={{ width }}
    >
      {children}
    </th>
  );
};

const TableRow: FC<PropsWithChildren> = ({ children }): JSX.Element => {
  return (
    <tbody>
      <tr>{children}</tr>
    </tbody>
  );
};

type TableDataProps = {
  width: number;
  em?: boolean;
};

const TableData: FC<PropsWithChildren<TableDataProps>> = ({
  children,
  width,
  em = false,
}): JSX.Element => {
  return (
    <td
      align="center"
      className={[
        "relative p-16 whitespace-pre-line align-top",
        em ? "text-lg" : "",
      ].join(" ")}
      style={{ width }}
    >
      {children}
    </td>
  );
};

type PriceProps = {
  productVariation: ProductVariationWithCatalogPagesResponse;
};

const Price: FC<PriceProps> = ({ productVariation }): JSX.Element => {
  const elm = useDisplayPrice(productVariation, {
    suffixClassName: "hidden",
    wrapperClassName: "justify-center",
    unitClassName: "text-sm",
  });

  return elm;
};

type SizeProps = {
  product: ProductDetailResponse;
  productVariation: ProductVariationWithCatalogPagesResponse;
};

const Size: FC<SizeProps> = ({ product, productVariation }): JSX.Element => {
  const { width, height, depth } = useProductUnits(product);
  const displaySizeForTable = useMemo(() => {
    // 単位が指定されていなければ、ミリメートルを表示する
    const unitString = "(mm)";

    const values = [];

    if (productVariation.width)
      values.push(
        `${width}: ${formatNumber(productVariation.width)} ${unitString}`,
      );

    if (productVariation.height)
      values.push(
        `${height}: ${formatNumber(productVariation.height)} ${unitString}`,
      );

    if (productVariation.depth)
      values.push(
        `${depth}: ${formatNumber(productVariation.depth)} ${unitString}`,
      );

    return values.join("\n");
  }, [productVariation, width, height, depth]);

  return <>{displaySizeForTable}</>;
};

type ActionBtnsProps = {
  productVariation: ProductVariationWithCatalogPagesResponse;
  product: ProductDetailResponse;
  mutate: LikeMutateHandler<ProductVariationWithCatalogPagesResponse>;
};

const ActionBtns: FC<ActionBtnsProps> = ({
  productVariation,
  product,
  mutate,
}) => {
  const { likeHandler } =
    useLikeToProduct<ProductVariationWithCatalogPagesResponse>(
      productVariation,
      product.kind,
      mutate,
      product.maker_id,
    );
  const kind = useMemo<ListItemKind>(
    () =>
      product.kind == ProductKind.BUILDING_MATERIAL
        ? ListItemKind.BUILDING_MATERIAL
        : ListItemKind.INTERIOR,
    [product],
  );

  return (
    <div className="flex gap-8">
      <MinimumLikeBtn
        hasLiked={productVariation.has_liked}
        onClick={likeHandler}
      />
      <MinimumAddListBtn selectedItem={productVariation} kind={kind} />
    </div>
  );
};

const ScrollButton: FC<{
  onClick: MouseEventHandler<HTMLButtonElement>;
  direction: "left" | "right";
}> = ({ onClick, direction }): JSX.Element => {
  return (
    <button
      className="flex items-center justify-center bg-white bg-opacity-70 w-[48px] h-[48px] rounded-full shadow-default"
      type="button"
      onClick={onClick}
    >
      {direction === "left" ? (
        <IconArrowLeft fill={theme.colors.gray[950]} width={32} height={32} />
      ) : (
        <IconArrowRight fill={theme.colors.gray[950]} width={32} height={32} />
      )}
    </button>
  );
};

type TableDataForActionProps = {
  width: number;
  product: ProductDetailResponse;
  productVariation: ProductVariationWithCatalogPagesResponse;
  mutate: LikeMutateHandler<ProductVariationWithCatalogPagesResponse>;
};
const TableDataForAction: FC<TableDataForActionProps> = ({
  width,
  product,
  productVariation,
  mutate,
}): JSX.Element => {
  const { isSampleAvailable, isIndirectAvailable } =
    useProductVariation(productVariation);
  const { hasSameSampleItem } = useCart();

  const duplicate = useMemo(() => {
    if (!productVariation.sample_item) return false;

    return hasSameSampleItem(productVariation);
  }, [productVariation, hasSameSampleItem]);

  return (
    <TableData width={width} key={productVariation.id}>
      <div className="space-y-8 pb-24">
        <div className="flex justify-end gap-8">
          <ActionBtns
            product={product}
            productVariation={productVariation}
            mutate={mutate}
          />
        </div>

        <div className="flex flex-col items-center space-y-12">
          {productVariation.upload_image && (
            <div className="flex justify-center">
              <ZoomableImage
                image={productVariation.upload_image}
                alt={productVariation.full_name}
              >
                <img
                  className="w-full object-contain max-w-[120px] aspect-square"
                  src={productVariation.upload_image.urls.small}
                  alt={`${productVariation.full_name}のバリエーションイメージ`}
                />
              </ZoomableImage>
            </div>
          )}

          {productVariation.name && (
            <p className="font-bold text-base">{productVariation.name}</p>
          )}

          <div className="flex flex-col items-center gap-8">
            {isSampleAvailable && product.maker.is_contracted && (
              <>
                {isIndirectAvailable && <SampleItemTSSDeliveryLabel />}
                <CartBtnWrapper
                  product={product}
                  productVariation={productVariation}
                  btnView={
                    <span className="w-full block bg-yellow-500 rounded-full lapto:px-20 px-12 py-12 text-sm flex-1 whitespace-nowrap ">
                      カートに追加
                    </span>
                  }
                />
              </>
            )}

            {duplicate && (
              <p className="text-danger text-sm">
                同じサンプルがすでにカートに入っています
              </p>
            )}
          </div>
        </div>
      </div>
    </TableData>
  );
};

type TableDataForDeliveryTimeProps = {
  width: number;
  productVariation: ProductVariationWithCatalogPagesResponse;
};
const TableDataForDeliveryTime: FC<TableDataForDeliveryTimeProps> = ({
  width,
  productVariation,
}): JSX.Element => {
  const deliveryTime = useDeliveryTimeString(productVariation);

  return <TableData width={width}>{deliveryTime}</TableData>;
};
