import { ProductDetailResponse } from "@/web-client/api";
import { FC, useMemo } from "react";
import Modal from "@/components/Modal";

import useDevice from "@/hooks/useDevice";
import { ProductVariationGroup } from "@/features/product_variation/hooks/useProductVariationGroup";
import useProductDetail from "@/features/product/hooks/useProductDetail";
import BorderList from "@/components/BorderList";
import ApplyableSampleItem from "@/features/sample_item/components/ApplyableSampleItem";

type Props = {
  isOpen: boolean;
  closeHandler: () => void;
  product: ProductDetailResponse;
  currentGroup?: ProductVariationGroup;
};

const ModalApplyableProductVariations: FC<Props> = ({
  isOpen,
  closeHandler,
  product,
  currentGroup,
}): JSX.Element => {
  const { isPc } = useDevice();
  const { applyableProductVariations } = useProductDetail(product);

  const currengGroupProductVarationIds = useMemo(() => {
    if (!currentGroup) return [];

    return currentGroup.variations.map(({ id }) => id);
  }, [currentGroup]);

  // 現在見ているバリエーションを最上部に配置する
  const sortedProductVariations = useMemo(() => {
    // 現在みているアイテムグループ & そのアイテムにサンプルがあれば
    return applyableProductVariations.sort((productVariation) =>
      currengGroupProductVarationIds.includes(productVariation.id) ? -1 : 1,
    );
  }, [applyableProductVariations, currengGroupProductVarationIds]);

  return (
    <Modal
      contentStyle={{
        minWidth: isPc ? 800 : "unset",
        maxWidth: 1024,
        width: "auto",
      }}
      isOpen={isOpen}
      closeHandler={() => {
        closeHandler();
      }}
    >
      <div className="px-12 laptop:px-32 laptop:pb-32 space-y-12">
        <h1 className="font-bold text-xl">請求可能なサンプル</h1>
        <BorderList borderStyle="solid">
          {sortedProductVariations.map((productVariation) => (
            <li key={productVariation.id}>
              <ApplyableSampleItem
                product={product}
                productVariation={productVariation}
              />
            </li>
          ))}
        </BorderList>
      </div>
    </Modal>
  );
};
export default ModalApplyableProductVariations;
