import {
  ProductDetailResponse,
  ProductVariationWithCatalogPagesResponse,
} from "@/web-client/api";
import { FC, useMemo, useState } from "react";
import { CatalogThumb } from "@/features/catalog/components/CatalogThumb";
import Modal from "@/components/Modal";
import IconArrowDown from "../../../assets/imgs/svg/icon-arrow-down.svg";
import FlexibleGrid from "@/components/FlexibleGrid";

type Props = {
  isOpen: boolean;
  closeHandler: () => void;
  product: ProductDetailResponse;
};

const ModalCatalogs: FC<Props> = ({
  isOpen,
  closeHandler,
  product,
}): JSX.Element => {
  // カタログが一件以上紐づいているバリエーション
  const variationsWithCatalog = useMemo(
    () =>
      product.variations.filter((variation) => variation.catalogs.length > 0),
    [product],
  );

  return (
    <Modal
      contentStyle={{
        maxWidth: 800,
        width: "100%",
      }}
      isOpen={isOpen}
      closeHandler={closeHandler}
    >
      <div className="bg-white space-y-12 px-12 laptop:px-24 pb-24 w-full">
        <h1 className="font-bold">掲載カタログ</h1>
        <div className="grid gap-8">
          {variationsWithCatalog.map((variation) => (
            <VariationCatalogGroup key={variation.id} variation={variation} />
          ))}
        </div>
      </div>
    </Modal>
  );
};

const VariationCatalogGroup: FC<{
  variation: ProductVariationWithCatalogPagesResponse;
}> = ({ variation }) => {
  const [open, setOpen] = useState(false);

  const displayName = useMemo(() => {
    return variation.item_number || variation.name || "";
  }, [variation]);

  return (
    <article className="rounded-sm border border-primary overflow-hidden cursor-pointer">
      <div
        onClick={(e) => {
          e.preventDefault();
          setOpen((prev) => !prev);
        }}
        className="flex items-center justify-between gap-8 p-8 hover:bg-gray-100"
      >
        <div className="flex items-center gap-8 overflow-hidden">
          <img
            className="w-[48px] h-[48px] object-contain"
            alt={displayName}
            src={variation.upload_image.urls.small}
          />
          <h1 className="text-sm truncate">
            {`${displayName}(${variation.catalogs.length})`}
          </h1>
        </div>
        <IconArrowDown
          style={{
            transform: `rotate(${open ? "180deg" : "0deg"})`,
          }}
          width={24}
          height={24}
        />
      </div>
      {open && (
        <div className="p-12 border-t border-primary">
          <FlexibleGrid
            maxWidth={240}
            minWidth={120}
            columnGap={24}
            rowGap={24}
          >
            {variation.catalogs.map((catalog) => (
              <li key={catalog.id}>
                <CatalogThumb catalog={catalog} />
              </li>
            ))}
          </FlexibleGrid>
        </div>
      )}
    </article>
  );
};

export default ModalCatalogs;
