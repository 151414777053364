import { useMemo } from "react";
import { ProductVariationGroup } from "./useProductVariationGroup";

interface UseProductVariationGroupTableResponse {
  hasItemNumber: boolean;
  hasPrice: boolean;
  hasSize: boolean;
  hasSizeText: boolean;
  hasMaterial: boolean;
  hasMaterialText: boolean;
  hasFireProtectionMaterial: boolean;
  hasFlamePreventionRegulation: boolean;
  hasFireRegistance: boolean;
  hasFormaldehyde: boolean;
  hasRemarks: boolean;
  hasProductUrl: boolean;
  hasCatalog: boolean;
  hasStandard: boolean;
}

const useProductVariationGroupTable = (
  group?: ProductVariationGroup,
): UseProductVariationGroupTableResponse => {
  const hasItemNumber = useMemo<boolean>(
    () =>
      group?.variations.some(
        (productVariation) =>
          //  品番が登録されている もしくは 廃番になっている プロダクトバリエーションが1つ以上ある
          !!productVariation.item_number || productVariation.is_discontinued,
      ),
    [group],
  );

  const hasPrice = useMemo<boolean>(
    () =>
      group?.variations.some(
        (productVariation) =>
          !!productVariation.max_price || !!productVariation.min_price,
      ),
    [group],
  );

  const hasSize = useMemo<boolean>(
    () =>
      group?.variations.some(
        (productVariation) =>
          !!productVariation.width ||
          !!productVariation.height ||
          !!productVariation.depth,
      ),
    [group],
  );

  const hasMaterial = useMemo<boolean>(
    () =>
      group?.variations.some(
        (productVariation) => productVariation.materials.length > 0,
      ),
    [group],
  );

  const hasFireProtectionMaterial = useMemo<boolean>(
    () =>
      group?.variations.some(
        (productVariation) => !!productVariation.fire_protection_material,
      ),
    [group],
  );

  const hasFlamePreventionRegulation = useMemo<boolean>(
    () =>
      group?.variations.some(
        (productVariation) => !!productVariation.flame_prevention_regulation,
      ),
    [group],
  );

  const hasFireRegistance = useMemo<boolean>(
    () =>
      group?.variations.some(
        (productVariation) => !!productVariation.fire_resistance,
      ),
    [group],
  );

  const hasFormaldehyde = useMemo<boolean>(
    () =>
      group?.variations.some(
        (productVariation) => !!productVariation.formaldehyde,
      ),
    [group],
  );

  const hasRemarks = useMemo<boolean>(
    () =>
      group?.variations.some((productVariation) => !!productVariation.remarks),
    [group],
  );

  const hasCatalog = useMemo<boolean>(
    () =>
      group?.variations.some(
        (productVariation) =>
          !!productVariation.catalog_url ||
          productVariation.catalogs.length > 0,
      ),
    [group],
  );

  const hasProductUrl = useMemo<boolean>(
    () =>
      group?.variations.some(
        (productVariation) => !!productVariation.product_url,
      ),
    [group],
  );

  const hasSizeText = useMemo<boolean>(
    () =>
      group?.variations.some(
        (productVariation) => !!productVariation.size_text,
      ),
    [group],
  );

  const hasMaterialText = useMemo<boolean>(
    () =>
      group?.variations.some(
        (productVariation) => !!productVariation.material_text,
      ),
    [group],
  );

  const hasStandard = useMemo<boolean>(
    () =>
      hasFireProtectionMaterial ||
      hasFlamePreventionRegulation ||
      hasFireRegistance ||
      hasFormaldehyde,
    [
      hasFireProtectionMaterial,
      hasFlamePreventionRegulation,
      hasFireRegistance,
      hasFormaldehyde,
    ],
  );

  return {
    hasItemNumber,
    hasPrice,
    hasSize,
    hasSizeText,
    hasMaterial,
    hasMaterialText,
    hasFireProtectionMaterial,
    hasFlamePreventionRegulation,
    hasFireRegistance,
    hasFormaldehyde,
    hasRemarks,
    hasCatalog,
    hasProductUrl,
    hasStandard,
  };
};
export default useProductVariationGroupTable;
