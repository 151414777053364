import { useEffect } from "react";
import useAnalytics from "./useAnalytics";
import { AnalyticsEventParameter } from "@/types/AnalyticsEventParameter";

const useViewEvent = (
  eventName: string,
  eventParams?: AnalyticsEventParameter,
  makerId?: number,
  disable = false,
): void => {
  const { event } = useAnalytics(makerId);
  useEffect(() => {
    if (disable) return;
    event(eventName, eventParams);
  }, [disable]);
};
export default useViewEvent;
