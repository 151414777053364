import { UploadImageResponse } from "@/web-client/api";
import useDisclosure from "@/hooks/useDisclosure";
import { FC, PropsWithChildren } from "react";
import ModalOriginalImage from "@/components/ModalOriginalImage";

type Props = {
  image: UploadImageResponse;
  alt: string;
};

const ZoomableImage: FC<PropsWithChildren<Props>> = ({
  image,
  alt,
  children,
}) => {
  const { isOpen, openHandler, closeHandler } = useDisclosure(false);

  return (
    <>
      <button className="relative group" type="button" onClick={openHandler}>
        {children}

        <div className="absolute inset-0 bg-black bg-opacity-30 text-white flex items-center justify-center opacity-0 group-hover:opacity-100 z-10">
          <i className="icon-Zoom-in text-3xl leading-none" />
        </div>
      </button>

      <ModalOriginalImage
        isOpen={isOpen}
        closeHandler={closeHandler}
        image={image}
        alt={alt}
      />
    </>
  );
};
export default ZoomableImage;
