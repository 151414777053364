import { FC } from "react";
import IconLaunch from "../assets/imgs/svg/icon_launch.svg";
import clsx from "clsx";
import { theme } from "tailwind.config";

type Props = {
  label: string;
  outer: boolean;
  wrapperClassName?: string;
  iconSize?: number;
};

const LinkText: FC<Props> = ({
  label,
  outer,
  iconSize = 16,
  wrapperClassName,
}): JSX.Element => {
  return (
    <div
      className={clsx(
        "inline-flex items-center gap-8 hover:underline",
        wrapperClassName,
      )}
    >
      {outer ? (
        <>
          <span>{label}</span>
          <IconLaunch
            width={iconSize}
            height={iconSize}
            fill={theme.colors.gray[600]}
          />
        </>
      ) : (
        <>{label}</>
      )}
    </div>
  );
};
export default LinkText;
