import { useState, useEffect, MutableRefObject } from "react";

type ScrollableValues = {
  scrollableWidth: number;
  scrollableHeight: number;
};

const useScrollableValues = <T extends HTMLElement>(
  ref?: MutableRefObject<T>,
): ScrollableValues => {
  const [scrollableWidth, setScrollableWidth] = useState(0);
  const [scrollableHeight, setScrollableHeight] = useState(0);

  useEffect(() => {
    const element = ref.current;

    function handleResize() {
      if (element) {
        const contentWidth = element.scrollWidth;
        const contentHeight = element.scrollHeight;
        const containerWidth = element.clientWidth;
        const containerHeight = element.clientHeight;

        const widthDifference = contentWidth - containerWidth;
        const heightDifference = contentHeight - containerHeight;

        setScrollableWidth(widthDifference > 0 ? widthDifference : 0);
        setScrollableHeight(heightDifference > 0 ? heightDifference : 0);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref, setScrollableWidth, setScrollableHeight]);

  return { scrollableWidth, scrollableHeight };
};

export default useScrollableValues;
