import { useCallback } from "react";
import { AnalyticsItemParameter } from "@/types/AnalyticsItemParameter";
import useConvertProductToAnalyticsCategoryParam from "./useConvertProductToAnalyticsCategoryParam";
import { useRouter } from "next/router";
import { ProductDetailResponse } from "@/web-client/api";

type useConvertProductDetailToAnalyticsParamReturn = {
  convert: (
    product: ProductDetailResponse,
  ) => AnalyticsItemParameter | undefined;
};

const useConvertProductDetailToAnalyticsParam =
  (): useConvertProductDetailToAnalyticsParamReturn => {
    const { query } = useRouter();
    const { convert: convertToCategoryParam } =
      useConvertProductToAnalyticsCategoryParam();

    const convert = useCallback(
      (product: ProductDetailResponse): AnalyticsItemParameter | undefined => {
        const productVariation = query.v_id
          ? product.variations.find((v) => v.id.toString() === query.v_id)
          : product.variations[0];

        if (!productVariation) return undefined;

        return {
          item_id: `PRODV_${productVariation.id}`,
          item_name: productVariation.full_name,
          item_variant: productVariation.item_number || "",
          item_brand: product.maker.name,
          ...convertToCategoryParam(product),
        };
      },
      [convertToCategoryParam, query],
    );

    return {
      convert,
    };
  };
export default useConvertProductDetailToAnalyticsParam;
