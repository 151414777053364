import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import useScrollableValues from "./useScrollableValue";
import { Easing, useAnimate } from "framer-motion";

type ScrollControl = {
  ref: MutableRefObject<HTMLElement | null>;
  scrollBy: (delta: number, duration: number, easing: Easing) => void;
  scrollPosition: number;
  isMax: boolean;
  isMin: boolean;
};
export enum ScrollControlDirection {
  Horizontal = 1,
  Vertical,
}

const useScrollControl = (direction: ScrollControlDirection): ScrollControl => {
  const ref = useRef<HTMLElement>(null);
  const [, animate] = useAnimate();

  const propName = useMemo(
    () =>
      direction === ScrollControlDirection.Horizontal
        ? "scrollLeft"
        : "scrollTop",
    [direction],
  );
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const { scrollableWidth, scrollableHeight } = useScrollableValues(ref);

  // スクロール可能な値
  const scrollableValue = useMemo(
    () =>
      direction === ScrollControlDirection.Horizontal
        ? scrollableWidth
        : scrollableHeight,
    [direction, scrollableWidth, scrollableHeight],
  );

  // スクロール位置が最小か
  const isMin = useMemo(() => scrollPosition <= 0, [scrollPosition]);

  // スクロール位置が最大か
  const isMax = useMemo(
    () => scrollPosition >= scrollableValue,
    [scrollableValue, scrollPosition],
  );

  // 渡した引数分スクロールする
  const scrollBy = useCallback(
    (delta, duration, easing) => {
      if (ref.current) {
        const newPosition = Math.max(0, ref.current[propName] + delta);
        animate(ref.current[propName], newPosition, {
          duration,
          ease: easing,
          onUpdate: (latest) => {
            ref.current[propName] = latest;
          },
        });
      }
    },
    [ref, propName, scrollPosition, animate],
  );

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const currentPosition = ref.current[propName];
        setScrollPosition(currentPosition);
      }
    };

    if (ref.current) {
      ref.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ref, setScrollPosition]);

  return {
    ref,
    scrollBy,
    scrollPosition,
    isMin,
    isMax,
  };
};
export default useScrollControl;
