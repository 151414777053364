const foramtFireProtectionMaterial = (value: number): string => {
  switch (value) {
    case 1:
      return "不燃";
    case 2:
      return "準不燃";
    case 3:
      return "難燃";
    default:
      return "表記なし";
  }
};

const formatFlamePreventionRegulation = (value: number): string => {
  switch (value) {
    case 1:
      return "防炎";
    default:
      return "表記なし";
  }
};

const formatFireRegistance = (value: number): string => {
  switch (value) {
    case 1:
      return "1時間耐火";
    case 2:
      return "30分耐火";
    case 3:
      return "1時間準耐火";
    case 4:
      return "45分準耐火";
    case 5:
      return "防火構造";
    default:
      return "表記なし";
  }
};

const formatFormaldehyde = (value: number): string => {
  switch (value) {
    case 1:
      return "F★★★★";
    case 2:
      return "F★★★";
    case 3:
      return "F★★";
    default:
      return "表記なし";
  }
};

export {
  foramtFireProtectionMaterial,
  formatFlamePreventionRegulation,
  formatFireRegistance,
  formatFormaldehyde,
};
