import { useCallback } from "react";

export type MutableEntity = { id: number };

export type UseArrayMutationReturn<T> = {
  update: (entities: T[], entity: T) => T[];
  remove: (entities: T[], entity: T) => T[];
};

const useArrayMutation = <
  T extends MutableEntity,
>(): UseArrayMutationReturn<T> => {
  const update = useCallback((entities, target) => {
    return entities.map((e) => (e.id === target.id ? target : e));
  }, []);

  const remove = useCallback((entities, target) => {
    return entities.filter((e) => e.id !== target.id);
  }, []);

  return {
    update,
    remove,
  };
};
export default useArrayMutation;
