import { FC, ReactNode } from "react";

type Props = {
  message: ReactNode;
  frontIcon?: ReactNode;
  severity: "info" | "error" | "success" | "warning";
};

const Alert: FC<Props> = ({ message, severity, frontIcon }) => {
  return (
    <div
      data-severity={severity}
      className="flex items-start p-8 rounded-xs gap-8 text-sm data-[severity=info]:bg-info data-[severity=error]:bg-danger data-[severity=success]:bg-valid data-[severity=warning]:bg-warning-light"
    >
      {frontIcon && <div className="flex-0">{frontIcon}</div>}
      <div className="flex-1">{message}</div>
    </div>
  );
};
export default Alert;
