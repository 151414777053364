import { ProductDetailResponse } from "@/web-client/api";
import useAnalytics from "@/hooks/useAnalytics";
import useProductDetail from "@/features/product/hooks/useProductDetail";
import { FC, useCallback } from "react";
import ProductContactBtn from "./ProductContactBtn";
import dynamic from "next/dynamic";
import useDisclosure from "@/hooks/useDisclosure";
const DynamicModalCatalogs = dynamic(
  () => import("@/features/catalog/components/ModalCatalogs"),
);

interface Props {
  product: ProductDetailResponse;
}

const ProductContactLinks: FC<Props> = ({ product }): JSX.Element => {
  const { event } = useAnalytics();
  const { isOpen, closeHandler, openHandler } = useDisclosure(false);

  const { telephoneContactable, formContactable, hasCatalogEntity } =
    useProductDetail(product);

  const telephonHandler = useCallback(() => {
    event("click_button", {
      content_type: "tel",
      item_id: `PROD_${product.id}`,
    });
    location.href = `tel:${product.maker.phone_number}`;
  }, [product, event]);

  const launchCatalogueHandler = useCallback(() => {
    if (hasCatalogEntity) {
      // モーダルを表示する
      openHandler();

      return;
    }
  }, [hasCatalogEntity, openHandler]);

  const formHandler = useCallback(() => {
    event("click_button", {
      content_type: "contact_form",
      item_id: `PROD_${product.id}`,
    });
    location.href = product.maker.contact_form_url;
  }, [product, event]);

  return (
    <div
      className={[
        "small:grid small:grid-cols-2 small:grid-rows-2 small:gap-8",
        "laptop:flex laptop:[&>*+*]:border-l laptop:[&>*+*]:border-primary laptop:[&>*]:pl-12 laptop:[&>*]:pr-12",
      ].join(" ")}
      style={{
        gridTemplateRows: "repeat(2, max-content)",
      }}
    >
      {hasCatalogEntity && (
        <div className="[&>*]:w-full laptop:first:pl-0">
          <ProductContactBtn
            onClick={launchCatalogueHandler}
            label="カタログ"
            iconSuffix="Catalogue"
          />
          <DynamicModalCatalogs
            isOpen={isOpen}
            product={product}
            closeHandler={closeHandler}
          />
        </div>
      )}

      {telephoneContactable && (
        <div className="[&>*]:w-full laptop:first:pl-0">
          <ProductContactBtn
            onClick={telephonHandler}
            label="電話"
            iconSuffix="Telephone"
          />
        </div>
      )}

      {formContactable && (
        <div className="[&>*]:w-full laptop:first:pl-0">
          <ProductContactBtn
            onClick={formHandler}
            label="外部お問い合わせフォーム"
            iconSuffix="Mail"
          />
        </div>
      )}
    </div>
  );
};

export default ProductContactLinks;
